// ========= Hero Slider
// tns({
//     container: ".hero-slider",
//     slideBy: "page",
//     autoplay: true,
//     autoplayButtonOutput: false,
//     mouseDrag: true,
//     gutter: 0,
//     items: 1,
//     nav: false,
//     controls: true,
//     controlsText: [
//         '<i class="lni lni-chevron-left"></i>',
//         '<i class="lni lni-chevron-right"></i>',
//     ],
// });

//======== Brand Slider
tns({
    container: ".brands-logo-carousel",
    autoplay: true,
    autoplayButtonOutput: false,
    mouseDrag: true,
    gutter: 15,
    nav: false,
    controls: false,
    autoplayTimeout: 3000,
    // controlsText: [
    //     '<i class="lni lni-chevron-left"></i>',
    //     '<i class="lni lni-chevron-right"></i>',
    // ],
    responsive: {
        0: {
            items: 2,
        },
        540: {
            items: 3,
        },
        768: {
            items: 4,
        },
        992: {
            items: 5,
        },
    },
});

// const finaleDate = new Date("February 15, 2023 00:00:00").getTime();

// const timer = () => {
//     const now = new Date().getTime();
//     let diff = finaleDate - now;
//     if (diff < 0) {
//         document.querySelector(".alert").style.display = "block";
//         document.querySelector(".container").style.display = "none";
//     }

//     let days = Math.floor(diff / (1000 * 60 * 60 * 24));
//     let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
//     let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
//     let seconds = Math.floor((diff % (1000 * 60)) / 1000);

//     days <= 99 ? (days = `0${days}`) : days;
//     days <= 9 ? (days = `00${days}`) : days;
//     hours <= 9 ? (hours = `0${hours}`) : hours;
//     minutes <= 9 ? (minutes = `0${minutes}`) : minutes;
//     seconds <= 9 ? (seconds = `0${seconds}`) : seconds;

//     document.querySelector("#days").textContent = days;
//     document.querySelector("#hours").textContent = hours;
//     document.querySelector("#minutes").textContent = minutes;
//     document.querySelector("#seconds").textContent = seconds;
// };
// timer();
// setInterval(timer, 1000);
